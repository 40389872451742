<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>

			<div class="w-100 h-100 position-relative overflow-hidden">

				<div class="w-100 h-100 d-flex">

					<div class="d-flex flex-column w-100 h-100">

						<workspace_nav label="Workspaces" />
			
						<div class="w-100 h-100 overflow-auto">
							<div class="container h-100 py-4">
								
								<div class="row h-100">

									<div class="col-12">

										<loader v-if="workspace_loading" />

										<div v-else-if="!workspaces.length">

											<div class="row h-100 justify-content-center">

												<div class="col-12 col-md-10 col-lg-6 col-xxl-5">

													<div class="card">

														<div class="card-body text-center">

															<span class="material-icons-outlined card-icon mx-auto mb-4">workspaces</span>

															<h1>Add a workspace.</h1>

															<div v-if="auth_user.type == 'admin'">

																<p>A workspace is a server where all of your websites will be stored. Click the button below to create your first workspace.</p>

																<btn_router_link
																	label="Add workspace" 
																	:route="{ name: 'workspaces.add' }" 
																	icon="add_circle_outline"
																	btn_class="btn btn-lg btn-outline-secondary" 
																/>

															</div>

															<p v-else class="mb-0">A workspace is a server where all of your websites will be stored. Ask your team admin to create your first workspace.</p>

														</div>

													</div>

												</div>

											</div>

										</div>

										<div v-else>

											<div class="row">

												<div v-for="workspace in workspaces" class="col-12 mb-4" :key="'workspace-' + workspace.id">

													<div class="card h-100 position-relative" style="min-height: 400px;">

														<div class="row h-100 m-0">

															<div class="col-12 col-md-4 border-end">
																<div class="card-body position-relative h-100 d-flex flex-column px-2 py-4">

																	<span v-if="workspace.is_created" class="badge bg-success position-absolute top-0 end-0 mt-2">Active</span>

																	<span v-else-if="!workspace.error_message" class="badge bg-info position-absolute top-0 end-0 mt-2">Processing</span>

																	<span v-else class="badge bg-danger position-absolute top-0 end-0 mt-2">Failed</span>

																	<div class="flex-grow-1">
																		<p class="h5 m-0 mb-4">{{ workspace.name }}</p>

																		<p class="form-text mb-2" v-if="workspace.domain">
																			Domain: https://{{ workspace.custom_domain ? workspace.custom_domain : workspace.domain }}
																			<a v-if="workspace.is_created" href="#" data-bs-toggle="modal" :data-bs-target="'#domain-' + workspace.id + '-modal'">(Change)</a>
																		</p>

																		<p class="form-text mb-2" v-if="workspace.domain">
																			IP address: {{ workspace.server_ip }}
																		</p>

																		<p class="form-text mb-4" v-if="workspace.is_created">Created: {{ format_date( workspace.is_created ) }}</p>

																		<div class="progress mb-2">
																			<div class="progress-bar" :class="workspace.sites.length >= workspace.limit ? 'bg-danger' : 'bg-success'" :style="'width: ' + get_percentage( workspace ) + '%;'"></div>
																		</div>

																		<p class="form-text">{{ workspace.sites.length }} of {{ workspace.limit }} sites used</p>
																	</div>
																	<div>
																		<btn_router_link 
																			v-if="auth_user.type == 'admin' && workspace.is_created"
																			label="Delete" 
																			:route="{ name: 'workspaces.destroy', params: { workspace_id: workspace.id } }" 
																			icon="cancel"
																			:btn_class="'btn p-0 text-danger ' + ( $route.name == 'workspaces.destroy' ? 'btn-primary text-white' : '' )" 
																		/>
																	</div>
																</div>
															</div>

															<div class="col bg-light">
																<div class="card-body position-relative h-100 d-flex align-items-center px-2 py-3">

																	<div class="w-100" v-if="workspace.sites.length">

																		<p class="h5 m-0 mb-4">Sites</p>

																		<div class="card my-2 position-relative" v-for="(site) in workspace.sites" :key="'site-' + site.id">

																			<div class="card-body p-4">
																				<p class="h6 m-0 mb-0">{{ site.name }}</p>
																			</div>

																			<span class="material-icons-outlined position-absolute end-0 top-50 translate-middle-y p-4">east</span>

																			<router-link :to="{ name: (!site.settings['brief_complete'] && !site.settings['skip_wizard_complete'] && site.settings['is_wizard'] ? 'site.add' : 'site.planning'), params: { workspace_id: workspace.id, site_id: site.id } }" class="stretched-link"></router-link>

																		</div>

																		<btn_router_link
																			v-if="(workspace.sites.length < workspace.limit || workspace.team_id == 1 || workspace.id == 4)"
																			label="Add site" 
																			:route="{ name: 'site.add', params: { workspace_id: workspace.id } }" 
																			icon="add_circle_outline"
																			:icon_after="true"
																			btn_class="btn btn-lg btn-outline-secondary w-100 justify-content-between" 
																		/>

																	</div>

																	<!-- <div class="w-100" v-else-if="!workspace.is_created && !workspace.error_message">
																		<loader />
																	</div> -->

																	<div class="w-100" v-else>
																		<div class="card">

																			<div class="card-body d-flex justify-content-between align-items-center">
																				<span>You haven't added any sites to this workspace.</span>

																				<btn_router_link
																					label="Add site" 
																					:route="{ name: 'site.add', params: { workspace_id: workspace.id } }" 
																					icon="add_circle_outline"
																					btn_class="btn btn-lg btn-outline-secondary" 
																				/>
																			</div>

																		</div>
																	</div>

																</div>
															</div>

														</div>

														<div class="modal fade" :id="'domain-' + workspace.id + '-modal'" tabindex="-1" :aria-labelledby="'domain-' + workspace.id + '-modalLabel'" aria-hidden="true">
															<div class="modal-dialog">
																<div class="modal-content">
																	<div class="modal-header">
																		<h5 class="modal-title" :id="'domain-' + workspace.id + '-modalLabel'">Domain details</h5>
																		<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
																	</div>
																	<div class="modal-body">
																		<form @submit.prevent="submit( workspace )">

																			<p class="alert alert-info small">
																				Before proceeding you need to setup an A record within the relevant domains DNS settings that points to {{ workspace.server_ip }}. If you're using a tool like CloudFlare make sure your A record has the "Proxied" option turned off or we won't be able to validate the IP.
																			</p>

																			<form_control
																				label="Domain"
																				type="text"
																				placeholder="eg: sites.yourdomain.com"
																				name="workspace.custom_domain"
																				:required="true"
																				:error="error"
																				v-model="workspace.custom_domain"
																			/>

																			<btn_submit 
																				label="Update your domain" 
																				icon="check"  
																				:icon_after="true" 
																				:loading="loading" 
																			/>

																		</form>
																	</div>
																</div>
															</div>
														</div>	

													</div>

												</div>

											</div>

										</div>

									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import loader from '@/components/loader'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import btn_router_link from '@/components/btn_router_link'
import form_control from '@/components/form_control'
import btn_submit from '@/components/btn_submit'
import workspace_nav from '@/components/workspace_nav'
import workspace_service from '@/services/workspace_service'

export default {
	name: 'workspaces',

	components: {
		layout,
		loader,
		main_nav,
		support_link,
		btn_router_link,
		form_control,
		btn_submit,
		workspace_nav
	},

	data() {
		return {
			workspace_loading: false,
			workspaces: [],
			error: {},
			loading: false
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

	},

	mounted()
	{
		this.get_workspaces()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_workspaces()
		{
			this.workspace_loading = true 

			this.reset_error()

			workspace_service.index().then(( response ) => {
				this.workspaces = response.data
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		format_date( date )
		{
			var new_date = new Date( date ).toDateString();

			return new_date
		},

		get_percentage( workspace )
		{
			var allowed = workspace.limit;

			var total = workspace.sites.length;

			return (total / allowed) * 100;
		},

		submit( workspace )
		{
			this.loading = true 

			this.reset_error()

			workspace_service.update( workspace.id, workspace ).then(( response ) => {
				this.workspaces = response.data

				window.$('.modal').modal('hide');
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},
	}
}
</script>

<style>

</style>
